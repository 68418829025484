
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as Styled from "./App.styles"
import React, { useEffect, useState } from 'react';
import Landing from './pages/Landing/Landing';
import * as Sentry from "@sentry/react";
import ScrollToTop from './helpers/ScrollToTop';
import useWindowDimensions from './helpers/CustomHooks/useWindowDimensions';
import { Div } from "atomize"
import Header from './layout/Header/Header';
import Cnt from './layout/Cnt/Cnt';
import Page404 from './pages/Page404/Page404';
import { config } from "./config";
import jwt_decode from "jwt-decode";
var createGuest = require("cross-domain-storage/guest");

const App: React.FC = () => {

  const [token, setToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const cg = async () => {
    var bazStorage = createGuest(config.app_site_url);
    await bazStorage.get("token", (error: any, value: string) => {
      // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
      if (value) {
        const j: any = jwt_decode(value)
        if (Date.now() >= (j?.exp * 1000)) {
          setToken("")
        } else {
          setToken(value)
        }
      }
      if (error) {
        setToken("")
      }
    });
  }

  useEffect(() => {
    cg()
    setInterval(() => {
      setIsLoading(false)
    }, [900])
  }, [])


  const { width } = useWindowDimensions()
  const [langVar, setLangVar] = useState<"EN" | "SI">("EN")
  return (
    <Styled.App  >
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact>
            <Div maxW={`${width}px`}>
              <Div overflow="hidden">
                <Header token={token} isLoading={isLoading} langVar={langVar} setLangVar={setLangVar} />
                <Cnt maxW={"800px"} flexDir={"column"}>
                  <Landing langVar={langVar} />
                </Cnt>
              </Div>
            </Div>
          </Route>
          <Route path="/">
            <Page404 />
          </Route>
        </Switch >
      </BrowserRouter >
    </Styled.App >
  );
}

export default Sentry.withProfiler(App);
