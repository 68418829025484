import React, { useEffect } from 'react'
import bg_img from "../../assets/images/landing_bg.svg"
import order_details_screenshot from "../../assets/images/order_details_screenshot.png"
import pricing from "../../assets/images/pricing.png"
import { Text, Div, Icon, Tag } from "atomize";
import renderIcon from '../../assets/Icons';
import PrimaryBtn from '../../components/PrimaryBtn/PrimaryBtn';
import SecondaryBtn from '../../components/SecondaryBtn/SecondaryBtn';
import usePreloadImages from '../../helpers/CustomHooks/usePreloadImages';
import useWindowDimensions from '../../helpers/CustomHooks/useWindowDimensions';
import Lang from '../../helpers/Text/Lang';
import { config } from '../../config';
import Border from '../../components/Border/Border';



const Landing: React.FC<{ langVar: "EN" | "SI" }> = ({ langVar }) => {

    usePreloadImages(false);
    const { width } = useWindowDimensions()

    useEffect(() => {
        document.body.style.overflowX = "hidden"

        return () => {
            document.body.style.overflowX = "unset"
        };
    });

    return (
        <>
            <Text tag="h1" textAlign="left" textSize={{ xs: "display2", md: "display3" }} textWeight="700" textColor="dark" m={{ t: width > 700 ? "4rem" : "1.5rem" }}>
                {Lang(langVar).landing.title}
            </Text>
            <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem" }}>
                {Lang(langVar).landing.subtitle}
            </Text>

            <Div d="flex" flexDir={{ xs: "column", md: "row" }} w="100%" align="flex-start" m={{ t: width > 700 ? "2rem" : "1rem" }}>
                <PrimaryBtn
                    handleSubmit={
                        (e: React.ChangeEvent<HTMLButtonElement>) => { window.location.href = `${config.app_site_url}/signup` }
                    }
                    suffixIcon={<Icon name="Lock" m={{ l: "5px" }} size="18px" color="brand100" />}
                    isLoading={false}
                    disabled={false}
                    m={{ r: "0.5rem", b:"0.25rem"}} 
                    w={{ xs: "100%", md: "180px" }}
                    text={Lang(langVar).btn.create_company_account}
                />
                <SecondaryBtn
                    handleSubmit={
                        (e: React.ChangeEvent<HTMLButtonElement>) => { window.location.href = `https://calendly.com/blazcargobite/introduction` }
                    }
                    suffixIcon={<Icon name="RightArrow" m={{ l: "5px" }} size="18px" color="brand600"/>}
                    isLoading={false}
                    disabled={false}
                    m={{ r: "0.5rem", b:"0.25rem"}} 
                    w={{ xs: "100%", md: "180px" }}
                    text={Lang(langVar).btn.book_call}
                />
            </Div>

            <img src={order_details_screenshot} style={{ width: width > 600 ? "calc(100% + 32rem" : "calc(100%)", margin: width > 600 ? "4rem -6rem 0" : "2rem 0 0"}} alt="" />
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%">
                <Text textSize="tiny" textTransform="uppercase" textColor="success700" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).landing.upgrade.label} </Text>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "0.25rem" }}> {Lang(langVar).landing.upgrade.title} </Text>
            </Div>

            <Box width={width} title={Lang(langVar).landing.upgrade.sections_title.visibility} text={Lang(langVar).landing.upgrade.sections_text.visibility}/>
            <Box width={width} title={Lang(langVar).landing.upgrade.sections_title.communication} text={Lang(langVar).landing.upgrade.sections_text.communication}/>
            <Box width={width} title={Lang(langVar).landing.upgrade.sections_title.orders} text={Lang(langVar).landing.upgrade.sections_text.orders}/>
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%">
                <Text textSize="tiny" textTransform="uppercase" textColor="warning700" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).landing.boost.label} </Text>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "0.25rem" }}> {Lang(langVar).landing.boost.title} </Text>
            </Div>

            <Box width={width} title={Lang(langVar).landing.boost.sections_title.quotes} text={Lang(langVar).landing.boost.sections_text.quotes}/>
            <Box width={width} title={Lang(langVar).landing.boost.sections_title.partners} text={Lang(langVar).landing.boost.sections_text.partners}/>
            <Box width={width} title={Lang(langVar).landing.boost.sections_title.marketplace} text={Lang(langVar).landing.boost.sections_text.marketplace}/>
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%">
                <Text textSize="tiny" textTransform="uppercase" textColor="info700" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).landing.scale.label} </Text>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "0.25rem" }}> {Lang(langVar).landing.scale.title} </Text>
            </Div>

            <Box width={width} title={Lang(langVar).landing.scale.sections_title.hub} text={Lang(langVar).landing.scale.sections_text.hub}/>
            <Box width={width} title={Lang(langVar).landing.scale.sections_title.data} text={Lang(langVar).landing.scale.sections_text.data}/>
            <Box width={width} title={Lang(langVar).landing.scale.sections_title.customization} text={Lang(langVar).landing.scale.sections_text.customization}/>

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "4rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>

            <Div d="flex" flexDir="column" align="flex-start" w="100%">
                <Text textSize="tiny" textTransform="uppercase" textColor="semiDark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).landing.pricing.label} </Text>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "0.25rem" }}> {Lang(langVar).landing.pricing.title} </Text>
            </Div>

            <img src={pricing} style={{ width: "100%", margin: width > 600 ? "4rem 0 1rem" : "2rem 0 0.5rem"}} alt="" />

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "4rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>

            <Text tag="h1" textAlign="left" textSize={{ xs: "display2", md: "display3" }} textWeight="700" textColor="dark" m={{ t: width > 700 ? "4rem" : "1.5rem" }}>
                {Lang(langVar).landing.ending_title}
            </Text>
            <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem" }}>
                {Lang(langVar).landing.ending_subtitle}
            </Text>

            <Div d="flex" flexDir={{ xs: "column", md: "row" }} w="100%" align="flex-start" m={{ t: width > 700 ? "2rem" : "1rem" }}>
                <PrimaryBtn
                    handleSubmit={
                        (e: React.ChangeEvent<HTMLButtonElement>) => { window.location.href = `${config.app_site_url}/signup` }
                    }
                    suffixIcon={<Icon name="Lock" m={{ l: "5px" }} size="18px" color="brand100" />}
                    isLoading={false}
                    disabled={false}
                    m={{ r: "0.5rem", b:"0.25rem"}} 
                    w={{ xs: "100%", md: "180px" }}
                    text={Lang(langVar).btn.create_company_account}
                />
                <SecondaryBtn
                    handleSubmit={
                        (e: React.ChangeEvent<HTMLButtonElement>) => { window.location.href = `https://calendly.com/blazcargobite/introduction` }
                    }
                    suffixIcon={<Icon name="RightArrow" m={{ l: "5px" }} size="18px" color="brand600"/>}
                    isLoading={false}
                    disabled={false}
                    m={{ r: "0.5rem", b:"0.25rem"}} 
                    w={{ xs: "100%", md: "180px" }}
                    text={Lang(langVar).btn.book_call}
                />
            </Div>

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "4rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>

            <Div d="flex" flexDir={{ xs: "column", md: "row" }} align="center" justify="space-between" w="calc(100vw - 2rem)" m={{ x: "1rem", t: "2rem", b: "2rem" }}>
                <Text textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark">
                    Copyright © 2023 Cargobite d.o.o.
                </Text>
                <Div m={{ l: { xs: '0', md: 'auto' } }} d="flex" flexDir={{ xs: "column", md: "row" }} align="center">
                    <Text m={{ l: "1rem" }} textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark">
                        VAT:HR91886004181
                    </Text>
                    <Text m={{ l: { xs: '0', md: '1rem' } }} textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark" hoverTextColor="dark" cursor="pointer" onClick={() => { window.location.href = `https://www.linkedin.com/company/cargobite/` }} className='link'>
                        LinkedIn
                    </Text>
                    <Text m={{ l: { xs: '0', md: '1rem' } }} textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark" hoverTextColor="dark" cursor="pointer" onClick={() => { window.location.href = 'mailto:info@cargobite.com' }} target="_blank" className='link'>
                        {Lang(langVar).get_in_touch}
                    </Text>
                </Div>
            </Div>
        </>
    )
}

export default Landing

const Box: React.FC<{ width: number, title: string, text: string}> = ({ width, title, text }) => {

    return (
        <Div m={{ t: width > 700 ? "4rem" : "2rem" }}>
            <Text tag="h3" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500" m={{ b: "1rem" }}>
                    {title}
            </Text>
            <Text textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark" m={{ b: "1rem" }}>
                {text}
            </Text>
        </Div>
    )
}