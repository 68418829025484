
export const GlobalTheme = {
  textSize: {
    size: {
      10: "10px",
      12: "12px",
      14: "14px",
      141: "14px",
      142: "14px",
      16: "16px",
      18: "18px",
      181: "18px",
      20: "20px",
      201: "20px",
      24: "24px",
      241: "24px",
      28: "28px",
      40: "40px",
      64: "64px",
      100: "100px"
    },
    height: {
      10: "16px",
      12: "20px",
      14: "22px",
      141: "24px",
      142: "32px",
      16: "28px",
      18: "21px",
      181: "26px",
      20: "34px",
      201: "32px",
      24: "28px",
      241: "36px",
      28: "40px",
      40: "52px",
      64: "72px",
      100: "120px"
    }
  },
  fontFamily: {
    primary: "Inter, serif",
  },
  rounded: {
    2: "2px",
    4: "4px",
    6: "6px",
    8: "8px"
  },

  colors: {
    dark: "#0A1F44",
    semiDark: "#4E5D78",
    light: "#8A94A6",
    semiLight: "#B0B7C3",
    primary: "#5278FF",
    neutralGrey: "#C9CED6",
    grey: "#F1F2F4",
    semiGrey: "#f1f2f8",
    greyBorder: "#E1E4E8",
    info: "#36BFEA",
    warning: "#FFAD0D",
    danger: "#F03D3D",
    success: "#0BB07B",
    brand600: "#4069FC",
    brand700: "rgb(2, 132, 254)",
    aqua: "#54CBF0",
    lightBlue: "#ECEEFF",
    background: "#FFF",
    brand100: "#F9F9FF"
  },
  shadows: {
    1: "0px 1px 1px rgba(0, 0, 0, 0.1)",
    2: "0px 3px 3px rgba(0, 0, 0, 0.1)",
    3: "0px 6px 6px rgba(0, 0, 0, 0.08)",
    4: "0px 16px 16px rgba(0, 0, 0, 0.1)",
    5: "0px 32px 40px rgba(0, 0, 0, 0.1)",
    6: "0px -16px 26px 3px rgba(0,0,0,0.1)"
  }

};


export type Font = keyof typeof GlobalTheme.textSize.size;
export type FontFamily = keyof typeof GlobalTheme.fontFamily;
export type Color = keyof typeof GlobalTheme.colors;
export type Shadows = keyof typeof GlobalTheme.shadows;

