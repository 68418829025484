import React from 'react'
import { Button, Icon } from "atomize";

const SecondaryBtn: React.FC<any> = ({ textSize = 14, styleBtn, prefixIcon, suffixIcon, disabled = false, handleSubmit, isLoading, text, w="100%", h = "48px", m, p }) => {
    return (
        <Button
            bg="background"
            border="1px solid"
            borderColor="primary"
            hoverBorderColor="brand600"
            p={{ xs: '0.6rem' }}
            m={{ r: "0.5rem", b: "0.25rem" }}
            justify="center"
            textColor="brand700"
            h={h}
            w={w}
            prefix={prefixIcon}
            suffix={suffixIcon}
            textSize={textSize}
            focusBorderColor="brand600"
            hoverShadow="2"
            disabled={disabled || isLoading}
            style={{ ...styleBtn }}
            onClick={handleSubmit}
        >
            {isLoading ?
                <Icon name="Loading" color="semiDark" size="20px" />
                :
                text ? text : "Confirm"
            }

        </Button>
    )
}

export default SecondaryBtn
