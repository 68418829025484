import Styled from 'styled-components'

interface BorderProps {
  color?: string
  margin?: string
  direction: string
}

export const Border = Styled.div<BorderProps>`

  background-color: ${props => props.color ? props.color : props.theme.colors.grey};
  ${props => props.direction === "horizontal" ?
    ` 
      width: 100%;
      height: 1px;
    `
    :
    `
      width: 1px;
      height: 100%;
    `
  }
  display: block;
  margin: ${props => props.margin ? props.margin : ""}; 

`;
