/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import * as Styled from "./Header.styles"
import { Div, Anchor, Icon, Text } from "atomize";
import { useHistory } from "react-router-dom";
import renderIcon from '../../assets/Icons';
import useWindowDimensions from '../../helpers/CustomHooks/useWindowDimensions';
import Border from '../../components/Border/Border';
import Lang from '../../helpers/Text/Lang';
import { config } from '../../config';
import Skeleton from '../../components/Skeleton/Skeleton';

const Header: React.FC<{
    token: string | null,
    isLoading: boolean,
    langVar: "EN" | "SI", setLangVar: (langVar: "EN" | "SI") => void
}> = ({
    token,
    isLoading,
    langVar, setLangVar }) => {

        const { width, height } = useWindowDimensions()

        const [open, setOpen] = useState<boolean>(false)
        const [blur, setBlur] = useState<boolean>(false)

        window.addEventListener("scroll", function (e: Event) {
            if (window.scrollY > 0) {
                setBlur(true)
            } else {
                setBlur(false)
            }
        }, true);

        const history = useHistory()

        return (
            <>
                <Styled.Header>
                    <Div style={{ zIndex: "101" }} left="0" w="100%" pos="fixed" className={blur && "header"}>
                        <Div d="flex" h="56px" w="100%" p={{ x: "1rem" }} align="center" justify="space-between"   >
                            <Div cursor="pointer" h="24px" d="flex" align="center" className="logo_wrapper" onClick={() => {
                                setOpen(false)
                                history.push("/")
                            }}>
                                {renderIcon("Logo")}
                            </Div>
                            <Div d={width > 522 ? "flex" : "none"} m={{ l: "auto" }} align="center">
                                <Anchor hoverTextColor="dark" m={{ r: "1rem" }} d="flex" align="center" transition textColor="semiDark" onClick={() => { setLangVar(langVar === "EN" ? "SI" : langVar === "SI" ? "EN" : langVar) }} textSize={12} textWeight="500" >
                                    <span>{langVar}</span>
                                    <span style={{ width: "18px", display: "flex", marginLeft: "0.5rem" }}>{renderIcon(langVar === "EN" ? "EnIcon" : "SiIcon")}</span>
                                </Anchor>

                                {isLoading ?
                                    <Skeleton height="24px" width="120px" />
                                    :
                                    token ?
                                        <Text cursor="pointer"
                                            onClick={() => { window.location.href = `${config.app_site_url}/signin` }} className='link'>
                                            {Lang(langVar).go_to_app}
                                        </Text>
                                        :
                                        <>
                                            <Text cursor="pointer"
                                                onClick={() => { window.location.href = `${config.app_site_url}/signin` }} className='link'>
                                                {Lang(langVar).log_in}
                                            </Text>
                                            <Text cursor="pointer"
                                                onClick={() => { window.location.href = `${config.app_site_url}/signup` }} className='link last_link'>
                                                {Lang(langVar).create_account}
                                            </Text>
                                        </>
                                }
                            </Div>

                            <Icon name={!open ? "Menu" : "Cross"} d={width > 522 ? "none" : "block"} pos="fixed" right="1rem" top="1.2rem" size="20px" style={{ zIndex: "101" }} hoverColor="dark" color="semiDark" cursor="pointer" onClick={() => {
                                setOpen(!open)
                            }} />
                        </Div>
                        <Div w="calc(100% - 2rem)" m={{ x: "1rem" }}>
                            <Border color='rgba(0, 0, 0, 0.1)' ></Border>

                        </Div>
                    </Div>
                    {
                        <Div pos="fixed" bg="background" w="100%" right="0" top="0" h={`${height}px`} style={{ zIndex: "100", transform: `translateX(${open ? "0" : "100%"})` }} transition>
                            <Div d="flex" flexDir="column" p="1rem">
                                <Text cursor="pointer"
                                    onClick={() => { window.location.href = 'mailto:info@cargobite.com' }}
                                    target="_blank" className='link_mobile first_link'>
                                    {Lang(langVar).get_in_touch}
                                </Text>
                                {token ?
                                    <Text cursor="pointer"
                                        onClick={() => { window.location.href = `${config.app_site_url}/signin` }} className='link_mobile'>
                                        {Lang(langVar).go_to_app}
                                    </Text>
                                    :
                                    <>
                                        <Text cursor="pointer"
                                            onClick={() => {
                                                setOpen(false)
                                                window.location.href = `${config.app_site_url}/signin`
                                            }}
                                            className='link_mobile'>
                                            {Lang(langVar).log_in}
                                        </Text>
                                        <Text cursor="pointer"
                                            onClick={() => {
                                                setOpen(false)
                                                window.location.href = `${config.app_site_url}/signup`
                                            }}
                                            className='link_mobile last_link'>
                                            {Lang(langVar).create_account}
                                        </Text>
                                    </>
                                }
                                <Anchor hoverTextColor="dark" d="flex" align="center" transition textColor="semiDark" onClick={() => {
                                    setOpen(false)
                                    setLangVar(langVar === "EN" ? "SI" : langVar === "SI" ? "EN" : langVar)
                                }} textSize={16} textWeight="500" >
                                    <span>{langVar}</span>
                                    <span className='langIcon' >{renderIcon(langVar === "EN" ? "EnIcon" : "SiIcon")}</span>
                                </Anchor>
                            </Div>

                        </Div>
                    }
                </Styled.Header>
                <Div h="57px"> </Div>
            </>
        )
    }

export default Header
