import styled from "styled-components";
import { GlobalTheme } from "../../components/GlobalTheme";

export const Header = styled.div`
.logo_wrapper {
    svg {
        height: 24px;
        width: auto;
    }
}

.header {
    @supports  ((-webkit-backdrop-filter: blur(10px)) or ( backdrop-filter: blur(10px))) {
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

.link {
    margin: 0 1rem 0 0;
    color: ${GlobalTheme.colors.semiDark};
    font-size: 12px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    
    &.last_link {
        margin: 0;
        color: ${GlobalTheme.colors.primary};
        &.selected {
            color: ${GlobalTheme.colors.brand700};
        }
        &:hover {
            color: ${GlobalTheme.colors.brand700};
        }
    }
    &.selected {
        color: ${GlobalTheme.colors.dark};
    }
    &:hover {
        color: ${GlobalTheme.colors.dark};
    }
}

.link_mobile {
    margin: 0 0 1rem 0;
    color: ${GlobalTheme.colors.semiDark};
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;

    &.first_link {
        margin: 4rem 0rem 1rem;
    }
    
    &.last_link {
        color: ${GlobalTheme.colors.primary};
        &.selected {
            color: ${GlobalTheme.colors.brand700};
        }
        &:hover {
            color: ${GlobalTheme.colors.brand700};
        }
    }
    &.selected {
        color: ${GlobalTheme.colors.dark};
    }
    &:hover {
        color: ${GlobalTheme.colors.dark};
    }
}

.langIcon {
    max-height: 22px!important;
    display: flex;
    margin-left: 0.5rem; 
    svg {
        width: 22px!important;
        height: 22px!important;
    }
}
`;


