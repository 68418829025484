import styled from "styled-components";

export const App = styled.div`
 
    input::-webkit-input-placeholder {
        color: ${props => props.theme.colors.semiLight}!important;
        opacity: mone;
    }
  
    input:-ms-input-placeholder { /* Internet Explorer */
        color: ${props => props.theme.colors.semiLight}!important;
        opacity: mone;
    }
    
    input::placeholder {
        color: ${props => props.theme.colors.semiLight}!important;
        opacity: mone;
    }

    input[type='date'], input[type='time'] {
      -webkit-appearance: none;
      -webkit-min-logical-width: 100% ;
  }

    textarea::-webkit-input-placeholder {
        color: ${props => props.theme.colors.semiLight}!important;
        font-family: ${props => props.theme.fontFamily.primary}!important;
        opacity: mone;
      }
      
      textarea:-moz-placeholder { /* Firefox 18- */
        color: ${props => props.theme.colors.semiLight}!important;
        font-family: ${props => props.theme.fontFamily.primary}!important;
        opacity: mone;  
      }
      
      textarea::-moz-placeholder {  /* Firefox 19+ */
        color: ${props => props.theme.colors.semiLight}!important;
        font-family: ${props => props.theme.fontFamily.primary}!important;
        opacity: mone;  
      }
      
      textarea:-ms-input-placeholder {
        color: ${props => props.theme.colors.semiLight}!important;
        font-family: ${props => props.theme.fontFamily.primary}!important;
        opacity: mone;  
      }
      
      textarea::placeholder {
        color: ${props => props.theme.colors.semiLight}!important;
        font-family: ${props => props.theme.fontFamily.primary}!important;
        opacity: mone;  
      }


`;
