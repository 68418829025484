import { useEffect, useState } from "react";
import bg_img from "../../assets/images/landing_bg.svg"
import order_details_screenshot from "../../assets/images/order_details_screenshot.png"


const unpackArr = [
  bg_img,
  order_details_screenshot
];

export default function usePreloadImages(
  unpackImages?: boolean,
  imgArr: string[] = []
): { allLoaded: boolean } {

  // this hook is used to preload images
  const [allLoaded, setAllLoaded] = useState(false);

  useEffect(() => {
    const newArr = unpackImages ? [...imgArr, ...unpackArr] : imgArr;

    if (newArr === null) return;
    let c = 0;
    newArr.forEach((img) => {
      const newImg = new Image();
      newImg.onload = () => {
        c += 1;
        if (c === newArr.length) {
          setAllLoaded(true);
        }
      };
      newImg.src = img;
    });
  }, [imgArr, unpackImages]);

  return {
    allLoaded
  };
}
